<template>
  <header class="p-3 text-bg-dark fixed-top">
    <div class="container">
      <div
        class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start"
      >
        <a
          href="/"
          class="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none"
        >
          <svg
            class="bi me-2"
            width="40"
            height="32"
            role="img"
            aria-label="Bootstrap"
          >
            <use xlink:href="#bootstrap" />
          </svg>
        </a>
        <ul
          class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0"
        >
          <li><a href="#" class="nav-link px-2 text-secondary">Home</a></li>
          <li><a href="#" class="nav-link px-2 text-white">Features</a></li>
          <li><a href="#" class="nav-link px-2 text-white">Pricing</a></li>
          <li><a href="#" class="nav-link px-2 text-white">FAQs</a></li>
          <li><a href="#" class="nav-link px-2 text-white">About</a></li>
        </ul>

        <div class="text-end">
          <button type="button" class="btn btn-outline-light me-2">
            Login
          </button>
          <button type="button" class="btn btn-warning">Sign-up</button>
        </div>
      </div>
    </div>
  </header>
  <div class="container py-5">
    <main class="mt-5">
      <div class="row">
        <div class="col-12 col-md-6">
          Описание
          <p>Тут текст описания и тд</p>
        </div>
        <div class="col-12 col-md-6">
          <div
            id="carouselExampleDark"
            class="carousel carousel-dark slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
            <div class="carousel-inner">
              <div class="carousel-item active" data-bs-interval="10000">
                <img
                  src="@/assets/slider/slide1.gif"
                  class="d-block w-100"
                  alt="..."
                />
              </div>
              <div class="carousel-item" data-bs-interval="2000">
                <img
                  src="@/assets/slider/slide2.gif"
                  class="d-block w-100"
                  alt="..."
                />
              </div>
              <div class="carousel-item">
                <img
                  src="@/assets/slider/slide3.gif"
                  class="d-block w-100"
                  alt="..."
                />
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
      <div class="row row-cols-1 row-cols-md-4 mb-3 text-center">
        <div class="col">
          <div class="card mb-3 rounded-3 shadow-sm">
            <div class="card-header py-3">
              <h4 class="my-0 fw-normal">Місяц</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                $700<small class="text-muted fw-light">/місяц</small>
              </h1>
              <ul class="list-unstyled mt-3 mb-4">
                <li>700 грн за період</li>
                <li>2 GB of storage</li>
                <li>Email support</li>
                <li>Help center access</li>
              </ul>
              <button
                type="button"
                class="w-100 btn btn-lg btn-outline-primary"
              >
                Sign up for free
              </button>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card mb-3 rounded-3 shadow-sm">
            <div class="card-header py-3">
              <h4 class="my-0 fw-normal">Квартал</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                $<small class="text-muted fw-light">/mo</small>
              </h1>
              <ul class="list-unstyled mt-3 mb-4">
                <li>630грн на місяц</li>
                <li>10 GB of storage</li>
                <li>Priority email support</li>
                <li>Help center access</li>
              </ul>
              <button type="button" class="w-100 btn btn-lg btn-primary">
                Get started
              </button>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card mb-3 rounded-3 shadow-sm border-primary">
            <div class="card-header py-3 text-bg-primary border-primary">
              <h4 class="my-0 fw-normal">Пів року</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                $<small class="text-muted fw-light">/mo</small>
              </h1>
              <ul class="list-unstyled mt-3 mb-4">
                <li>595грн місяц</li>
                <li>15 GB of storage</li>
                <li>Phone and email support</li>
                <li>Help center access</li>
              </ul>
              <button type="button" class="w-100 btn btn-lg btn-primary">
                Contact us
              </button>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card mb-3 rounded-3 shadow-sm border-primary">
            <div class="card-header py-3 text-bg-primary border-primary">
              <h4 class="my-0 fw-normal">Рік</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                $520<small class="text-muted fw-light">/mo</small>
              </h1>
              <ul class="list-unstyled mt-3 mb-4">
                <li>6000 грн місяц</li>
                <li>15 GB of storage</li>
                <li>Phone and email support</li>
                <li>Help center access</li>
              </ul>
              <button type="button" class="w-100 btn btn-lg btn-primary">
                Contact us
              </button>
            </div>
          </div>
        </div>
      </div>

      <h2 class="display-6 text-center mb-4">Compare plans</h2>

      <div class="table-responsive">
        <table class="table text-center">
          <thead>
            <tr>
              <th style="width: 34%"></th>
              <th style="width: 22%">Free</th>
              <th style="width: 22%">Pro</th>
              <th style="width: 22%">Enterprise</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" class="text-start">Public</th>
              <td>
                <svg class="bi" width="24" height="24">
                  <use xlink:href="#check" />
                </svg>
              </td>
              <td>
                <svg class="bi" width="24" height="24">
                  <use xlink:href="#check" />
                </svg>
              </td>
              <td>
                <svg class="bi" width="24" height="24">
                  <use xlink:href="#check" />
                </svg>
              </td>
            </tr>
            <tr>
              <th scope="row" class="text-start">Private</th>
              <td></td>
              <td>
                <svg class="bi" width="24" height="24">
                  <use xlink:href="#check" />
                </svg>
              </td>
              <td>
                <svg class="bi" width="24" height="24">
                  <use xlink:href="#check" />
                </svg>
              </td>
            </tr>
          </tbody>

          <tbody>
            <tr>
              <th scope="row" class="text-start">Permissions</th>
              <td>
                <svg class="bi" width="24" height="24">
                  <use xlink:href="#check" />
                </svg>
              </td>
              <td>
                <svg class="bi" width="24" height="24">
                  <use xlink:href="#check" />
                </svg>
              </td>
              <td>
                <svg class="bi" width="24" height="24">
                  <use xlink:href="#check" />
                </svg>
              </td>
            </tr>
            <tr>
              <th scope="row" class="text-start">Sharing</th>
              <td></td>
              <td>
                <svg class="bi" width="24" height="24">
                  <use xlink:href="#check" />
                </svg>
              </td>
              <td>
                <svg class="bi" width="24" height="24">
                  <use xlink:href="#check" />
                </svg>
              </td>
            </tr>
            <tr>
              <th scope="row" class="text-start">Unlimited members</th>
              <td></td>
              <td>
                <svg class="bi" width="24" height="24">
                  <use xlink:href="#check" />
                </svg>
              </td>
              <td>
                <svg class="bi" width="24" height="24">
                  <use xlink:href="#check" />
                </svg>
              </td>
            </tr>
            <tr>
              <th scope="row" class="text-start">Extra security</th>
              <td></td>
              <td></td>
              <td>
                <svg class="bi" width="24" height="24">
                  <use xlink:href="#check" />
                </svg>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>

    <footer class="pt-4 my-md-5 pt-md-5 border-top">
      <div class="row">
        <div class="col-12 col-md">
          <img
            class="mb-2"
            src="@/assets/logo.png"
            alt=""
            width="24"
            height="19"
          />
          <small class="d-block mb-3 text-muted">&copy; 2017–2022</small>
        </div>
        <div class="col-6 col-md">
          <h5>Features</h5>
          <ul class="list-unstyled text-small">
            <li class="mb-1">
              <a class="link-secondary text-decoration-none" href="#"
                >Cool stuff</a
              >
            </li>
            <li class="mb-1">
              <a class="link-secondary text-decoration-none" href="#"
                >Random feature</a
              >
            </li>
            <li class="mb-1">
              <a class="link-secondary text-decoration-none" href="#"
                >Team feature</a
              >
            </li>
            <li class="mb-1">
              <a class="link-secondary text-decoration-none" href="#"
                >Stuff for developers</a
              >
            </li>
            <li class="mb-1">
              <a class="link-secondary text-decoration-none" href="#"
                >Another one</a
              >
            </li>
            <li class="mb-1">
              <a class="link-secondary text-decoration-none" href="#"
                >Last time</a
              >
            </li>
          </ul>
        </div>
        <div class="col-6 col-md">
          <h5>Resources</h5>
          <ul class="list-unstyled text-small">
            <li class="mb-1">
              <a class="link-secondary text-decoration-none" href="#"
                >Resource</a
              >
            </li>
            <li class="mb-1">
              <a class="link-secondary text-decoration-none" href="#"
                >Resource name</a
              >
            </li>
            <li class="mb-1">
              <a class="link-secondary text-decoration-none" href="#"
                >Another resource</a
              >
            </li>
            <li class="mb-1">
              <a class="link-secondary text-decoration-none" href="#"
                >Final resource</a
              >
            </li>
          </ul>
        </div>
        <div class="col-6 col-md">
          <h5>About</h5>
          <ul class="list-unstyled text-small">
            <li class="mb-1">
              <a class="link-secondary text-decoration-none" href="#">Team</a>
            </li>
            <li class="mb-1">
              <a class="link-secondary text-decoration-none" href="#"
                >Locations</a
              >
            </li>
            <li class="mb-1">
              <a class="link-secondary text-decoration-none" href="#"
                >Privacy</a
              >
            </li>
            <li class="mb-1">
              <a class="link-secondary text-decoration-none" href="#">Terms</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup></script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
